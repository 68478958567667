import React, { useState, useEffect } from 'react';
import { FaGithub } from 'react-icons/fa';
import ecb1 from '../Assets/ecb1.png';
import ecb2 from '../Assets/ecb2.png';
import lms1 from '../Assets/lms1.png';
import lms2 from '../Assets/lms2.png';
import salon1 from '../Assets/salon1.png';
import auc1 from '../Assets/auc1.png';
import auc2 from '../Assets/auc2.png';

function Projects() {
    const projectData = [
        {
            id: 1,
            images: [salon1],
            title: "Salon Management System",
            description: "A dynamic scheduling and inventory management system that improved efficiency by 50%.",
            technologies: ["React.js", "Spring Boot", "MySQL", "CSS3", "Bootstrap","RESTful API"],
            githubLink: "https://github.com/MohamadSiyam66/Salon-Management-System"
        },
        {
            id: 2,
            images: [ecb1, ecb2],
            title: "Electricity Billing System",
            description: "A comprehensive system for managing electricity payments with Spring Boot and SQL integration.",
            technologies: ["React.js", "Spring Boot", "Java", "MySQL" , "Tailwind CSS"],
            githubLink: "https://github.com/MohamadSiyam66/Electricity-Billing-System"
        },
        {
            id: 3,
            images: [lms1, lms2],
            title: "Learning Management System",
            description: "A desktop application to manage students, lecturers and GPA calculations",
            technologies: ["Java", "OOP Concepts", "Java Swing", "MySQL"],
            githubLink: "https://github.com/MohamadSiyam66/JavaMiniFinal-V3"
        },
        {
            id: 4,
            images: [auc1, auc2],
            title: "Online Auction Platform",
            description: "A dynamic platform that allows users to participate in online auctions, manage bids, and track their items in real-time.",
            technologies: ["React.js", "Spring Boot", "MySQL" , "HTML5", "CSS3"],
            githubLink: "https://github.com/MohamadSiyam66/Online-Auction-Platform"
        },
    ];

    const [currentImage, setCurrentImage] = useState(0);

    // Function to change image every 2 seconds if there are two images
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage === 0 ? 1 : 0)); // Toggle between 0 and 1
        }, 2000);

        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, []);

    return (
        <section id="projects" className="flex items-center justify-center flex-col gap-12 relative text-white py-16">
            <div className="flex items-center justify-center text-left mb-6">
                <h1 className="text-4xl sm:text-5xl font-bold font-custom text-center">
                    My{' '}
                    <span className="text-indigo-400">
                        Projects
                    </span>
                </h1>
                {/* GitHub Icon */}
                <a
                    href="https://github.com/MohamadSiyam66"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-4 mt-2 text-3xl text-indigo-400 hover:text-indigo-500 transition duration-300"
                >
                    <FaGithub />
                </a>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-6xl px-4">
                {projectData.map((project) => (
                    <div
                        key={project.id}
                        className="bg-gray-800 rounded-lg shadow-lg p-6 flex flex-col items-center transition-transform duration-300 border-2 border-transparent relative group"
                    >
                        {/* Gradient Shadow */}
                        <div className="absolute bottom-0 left-0 w-full h-4 bg-gradient-to-r from-indigo-400 to-transparent rounded-b-lg"></div>
                        {/* Glow Border Effect */}
                        <div className="absolute inset-0 rounded-lg border-2 border-transparent group-hover:border-indigo-500 group-hover:shadow-indigo-500/50 transition-all duration-300"></div>

                        {/* Image Display */}
                        <div className="w-full h-48 overflow-hidden rounded-md mb-4 relative">
                            {project.images.length > 1 ? (
                                <img
                                    src={project.images[currentImage]}
                                    alt={project.title}
                                    className="w-full h-full object-cover transition-opacity duration-2000 ease-in-out"
                                />
                            ) : (
                                <img
                                    src={project.images[0]}
                                    alt={project.title}
                                    className="w-full h-full object-cover"
                                />
                            )}
                        </div>

                        <h2 className="text-2xl font-semibold text-center">{project.title}</h2>
                        <p className="text-sm text-gray-300 mb-4 text-center">{project.description}</p>
                        <div className="flex flex-wrap justify-center gap-2">
                            {project.technologies.map((tech, index) => (
                                <span
                                    key={index}
                                    className="px-3 py-1 bg-indigo-500 text-sm rounded-full text-white"
                                >
                                    {tech}
                                </span>
                            ))}
                        </div>

                        {/* Separate GitHub Link Container */}
                        <div className="mt-4 relative z-10">
                            <a
                                href={project.githubLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-indigo-400 flex items-center justify-center hover:text-indigo-500 transition duration-300"
                            >
                                <FaGithub className="mr-2" />
                                View on GitHub
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projects;